import React, { Component } from "react";
import { Col, Row } from "@maxeb/admin-ui";
import ScrollAnimation from "../../Shared/ScrollAnimation";

export default class AboutArtistFilm extends Component {
  render() {
    return (
      <>
        <Row vertical spacing={32} root={0}>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/LnfLFGQZ3J0" //LnfLFGQZ3J0
                  title="Sasha Okun | Film 1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                Sasha Okun in the Studio, 2018
              </div>
            </ScrollAnimation>
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/CvUQJECSrAI" //LnfLFGQZ3J0
                  title="Sasha Okun | Film 2"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                Sasha Okun, State Russia Museum, 2019
              </div>
            </ScrollAnimation>
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  title="Sasha Okun | Film 3"
                  width="100%"
                  src="https://www.youtube.com/embed/Gqo7HVuVNqc"
                  //src="https://www.youtube.com/embed/Wh2kpBDngyY"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                'Villa dei Misteri' in the Studio, 2020
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </>
    );
  }
}
