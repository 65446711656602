import React, { useState } from "react";
import { ArtworkPublic } from "@maxeb/art-sdk/dist/interfaces/Artwork";
import { Col, Container, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import ArtworksNavigation from "./ArtworksNavigation";
import CollectionName from "./CollectionName";

interface IProps {
  magnifierRadius: number;
  onClose?: () => void;
}
interface IState {
  active: ArtworkPublic | null;
  open: boolean;
  index: number;
}

const styles = (theme: ITheme) => ({
  info: {
    position: "absolute",
    bottom: 0,
    fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "1.3rem",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  infoResp: {
    fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "1.3rem",
    display: "block",
    margin: "16px 0px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  nav: {
    position: "relative",
    minHeight: "494px",
    [theme.breakpoints.up("xl")]: {
      minHeight: "554px",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "calc(100vh - 176px)",
    width: "auto",
    height: "auto",
    paddingTop: "16px",
    cursor: "none",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "calc(100% - 16px)",
    },
  },
  backgroundImg: {
    boxShadow: "0 5px 10px -2px rgba(0, 0, 0, 0.3)",
    pointerEvents: "none",
    position: "absolute",
    border: "4px solid #efefef",
    zIndex: 99,
    display: "block",
    transition: "opacity 0.2s",
  },
  shadow: {
    backgroundColor: "rgb(21, 21, 21)",
    position: "fixed",
    width: "100%",
    height: "calc(100% - 39px)",
    zIndex: "1000",
    left: 0,
    top: 0,
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "16px 0px",
      height: "100%",
    },
  },
  title: {
    position: "fixed",
    backgroundColor: theme.palette.get("default"),
    margin: "0px 0px 0px  !important",
    padding: "8px 16px !important",
    fontSize: "1.2rem",
    textAlign: "left",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

function getCategory(categoryId: string) {
  switch (categoryId) {
    case "e347144e-189a-4bea-888b-296ae2879245":
      return "OIL ON BOARD";
    case "e1a08a34-15d0-4589-9215-03452238cc4c":
      return "PENCIL";
    default:
      return "PENCIL";
  }
}

export default function Artworks(props: IProps) {
  const classes = useClasses(styles);
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState<IState>({
    active: null,
    open: false,
    index: 0,
  });

  const [magnifierState, setMagnifierState] = useState({
    top: 0,
    left: 0,
    offsetX: 0,
    offsetY: 0,
  });

  return (
    <Container>
      <Row>
        <Col lg={20 / 3}>
          <CollectionName />
          {state.active && (
            <>
              <div style={{ position: "relative" }}>
                <img
                  className={classes.img}
                  src={state.active?.thumb?.url}
                  alt={state.active?.title}
                  onClick={() => {
                    setState({ ...state, open: true });
                  }}
                  onMouseMove={(e) => {
                    setIsVisible(true);
                    const smallImage = e.currentTarget;
                    const x = e.nativeEvent.offsetX;
                    const y = e.nativeEvent.offsetY;
                    setMagnifierState({
                      top: y - props.magnifierRadius,
                      left: x - props.magnifierRadius,
                      offsetX:
                        (x / smallImage.width) * smallImage.naturalWidth -
                        props.magnifierRadius,
                      offsetY:
                        (y / smallImage.height) * smallImage.naturalHeight -
                        props.magnifierRadius,
                    });
                  }}
                  onMouseLeave={() => setIsVisible(false)}
                />
                <div
                  className={classes.backgroundImg}
                  style={{
                    //issue with shorthand background property during rerender
                    //workaround is to set each property and not rely on the shorthand
                    backgroundImage: `url("${state.active?.thumb?.url}")`,
                    backgroundPosition: "0% 0%",
                    backgroundSize: "auto auto",
                    backgroundRepeat: "no-repeat",
                    backgroundOrigin: "padding-box",
                    backgroundClip: "border-box",
                    backgroundAttachment: "scroll",
                    backgroundColor: "#fff",
                    width: 2 * props.magnifierRadius,
                    height: 2 * props.magnifierRadius,
                    borderRadius: props.magnifierRadius,
                    border: "3px solid #000",
                    top: magnifierState.top + "px",
                    left: magnifierState.left + "px",
                    backgroundPositionX: -1 * magnifierState.offsetX,
                    backgroundPositionY: -1 * magnifierState.offsetY,
                    opacity: isVisible ? 1 : 0,
                  }}
                />
              </div>
            </>
          )}
          {state.active && (
            <div className={classes.infoResp}>
              {state.active.title}
              <br />
              {state.active.year}
              <br />
              {getCategory(state.active.categoryId || "")}
              <br />
              {state.active.height} x {state.active.width} cm
              <br />
            </div>
          )}
        </Col>
        <Col lg={10 / 3} override={{ col: classes.nav }} stretch>
          <ArtworksNavigation
            setActive={(active, index) => {
              setState({ ...state, active, index });
            }}
            setOpen={(open) => {
              setState({ ...state, open });
            }}
            open={state.open}
            index={state.index}
            active={state.active}
          />
          {state.active && (
            <div className={classes.info}>
              {state.active.title}
              <br />
              {state.active.year}
              <br />
              {getCategory(state.active.categoryId || "")}
              <br />
              {state.active.customId
                ? state.active.customId
                : `${state.active.height} x ${state.active.width} cm`}

              <br />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
