import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import PendingContent from "./resources/components/Elements/PendingContent";
import ErrorContent from "./resources/components/Elements/ErrorContent";
import Header from "./resources/components/Elements/Header";
import StartPage from "./resources/components/Pages/StartPage";
import AboutPage from "./resources/components/Pages/About/index";
import SeriesPage from "./resources/components/Pages/Series";
//import ArtworksPage from "./resources/components/Pages/Artworks/index";
import ContactPage from "./resources/components/Pages/Contact";
import Exhibitions from "./resources/components/Pages/Exhibitions";
import LiteraturePage from "./resources/components/Pages/Literature";
import Footer from "./resources/components/Elements/Footer";
import "./App.scss";
import Artworks from "./resources/components/Pages/Series/Artworks";

class App extends Component {
  state = {
    pending: true,
    error: false,
  };

  componentDidMount() {}

  contentWrapper(component: JSX.Element) {
    const { pending, error } = this.state;

    if (pending) return <PendingContent />;
    if (error) return () => <ErrorContent text="Server is not reachable." />;
    return component;
  }

  render() {
    return (
      <Router>
        <header>
          <Header>
            <Link to="/about">ABOUT</Link>
            <Link to="/artworks">ARTWORKS</Link>
            <Link to="/literature">LITERATURE</Link>
            <Link to="/exhibitions">EXHIBITIONS</Link>
            <Link to="/contact">CONTACT</Link>
          </Header>
        </header>
        <main style={{ minHeight: "calc(100vh - 210px)" }}>
          <Routes>
            <Route path="/" element={<StartPage />}></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/artworks" element={<SeriesPage />}></Route>
            <Route
              path="/artworks/:id"
              element={<Artworks magnifierRadius={50} />}
            ></Route>
            <Route path="/exhibitions" element={<Exhibitions />}></Route>
            <Route path="/literature" element={<LiteraturePage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
          </Routes>
        </main>
        <Footer />
      </Router>
    );
  }
}

export default App;
