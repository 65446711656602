import { ITheme, useClasses } from "@maxeb/admin-ui";
import React from "react";

const style = (theme: ITheme) => ({
  list: {
    listStyle: "none",
    margin: "0 0 16px 0",
    padding: 0,
  },
  link: {
    padding: "5px 5px 5px 0px",
    minWidth: "48px",
    display: "inline-block",
    color: "#212529",
    "&:hover": {
      textDecoration: "none",
      fontWeight: 700,
    },
  },
});

export default function AboutNavigation() {
  const classes = useClasses(style);
  return (
    <nav>
      <ul className={classes.list}>
        <li className="nav-item">
          <a className={classes.link} href="/about#bio">
            BIOGRAPHY
          </a>
        </li>
        <li className="nav-item">
          <a className={classes.link} href="/about#film">
            FILM
          </a>
        </li>
        <li className="nav-item ">
          <a className={classes.link} href="/about#cv">
            CV
          </a>
        </li>
      </ul>
    </nav>
  );
}
